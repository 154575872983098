import { gsap } from "gsap";

export default function Surfing(els) {
  const surfing = document.querySelector(".surfing-gallery");
  let isSurfingOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      console.log("test");
      if (!isSurfingOpen) {
        isSurfingOpen = true;
        surfing.classList.remove("hidden");
        gsap.fromTo(surfing, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isSurfingOpen = false;
        gsap.fromTo(
          surfing,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => surfing.classList.add("hidden"),
          }
        );
      }
    })
  );
}
