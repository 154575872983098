const PhotoSwipe = require("photoswipe");
const PhotoSwipeUI_Default = require("photoswipe/dist/photoswipe-ui-default");

function addCss(siteUrl = "/") {
  const link = document.querySelector(".photoswipe");
  if (!link) {
    const el = document.createElement("link");
    el.rel = "stylesheet";
    el.href = siteUrl + "photoswipe.css";
    document.head.appendChild(el);
  }
}

export default function Gallery(el) {
  console.log("gallery", el);

  addCss(el.dataset?.siteUrl);
  const images = JSON.parse(el.dataset.images);

  el.addEventListener("click", () => {
    const pswpElement = document.querySelectorAll(".pswp")[0];
    // define options (if needed)
    const options = {
      // history & focus options are disabled on CodePen
      history: false,
      focus: false,

      showAnimationDuration: 1000,
      hideAnimationDuration: 1000,
    };

    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      images,
      options
    );

    gallery.init();
  });
}
