import { gsap } from "gsap";

export default function Film(els) {
  const film = document.querySelector(".film-gallery");
  let isFilmOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      console.log("test");
      if (!isFilmOpen) {
        isFilmOpen = true;
        film.classList.add("hidden");
        gsap.fromTo(film, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isFilmOpen = false;
        gsap.fromTo(
          film,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => film.classList.remove("hidden"),
          }
        );
      }
    })
  );
}
