import { gsap } from "gsap";

export default function Lifestyle(els) {
  const lifestyle = document.querySelector(".lifestyle-gallery");
  let isLifestyleOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      console.log("test");
      if (!isLifestyleOpen) {
        isLifestyleOpen = true;
        lifestyle.classList.add("hidden");
        gsap.fromTo(lifestyle, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isLifestyleOpen = false;
        gsap.fromTo(
          lifestyle,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => lifestyle.classList.remove("hidden"),
          }
        );
      }
    })
  );
}
